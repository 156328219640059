@use "@functions/theme.scss";
@use "@mixins/breakpoints.scss";
@use "@mixins/shadows.scss";
@use "@variables/colors.scss";
@use "@variables/shape.scss";
@use "@mixins/typography.scss";

@use "sass:color";

.root {
  border-radius: theme.border-radius(1);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 530px;
  min-width: 100px;
  padding: theme.spacing(1.25, 1.5, 1);
}

.close {
  position: absolute;
  top: theme.spacing(0.25);
  right: theme.spacing(0.5);
  padding: theme.spacing(1);
}

.closeIcon {
  width: 22px;
  height: 22px;
}

.content {
  @include typography.body2;

  & a {
    color: colors.$primary-light;
  }

  &--dismissible {
    padding-right: theme.spacing(4);  
  }
}

.colors {
  &--default {
    background-color: colors.$black;
    color: colors.$white;
  }

  &--info {
    background-color: color.scale(colors.$primary-light, $lightness: 80%);
    color: colors.$primary-dark;
  }

  &--error {
    background-color: color.scale(colors.$error-light, $lightness: 20%);
    color: colors.$error-dark;
  }

  &--warning {
    background-color: colors.$warning-light;
    color: colors.$warning-dark;
  }

  &--success {
    background-color: colors.$success-light;
    color: colors.$success-dark;
  }
}

.actionsWrap {};
.actionBtn {
  padding-left: 0;
}

.message {
  white-space: "pre-line";
}