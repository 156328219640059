@use "/src/styles/variables/colors.scss";
@use "/src/styles/variables/dims.scss";
@use "@functions/theme.scss";

.snackbarMessage {
  padding-right: theme.spacing(2);
}

.appVersionRoot {
  max-width: 320px;
}

.appVersionMessage {
  display: flex;
  align-items: stretch;
  gap: theme.spacing(1);

  &__content {
    display: flex;
    flex-direction: column;
    gap: theme.spacing(0.5);
    align-items: flex-start;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }
}
