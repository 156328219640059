@use "@mixins/breakpoints.scss";

// Taken from mui default theme: https://mui.com/material-ui/customization/default-theme/
@mixin body1 {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.3;
}

@mixin body2 {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3;
}

@mixin caption {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.3;
}

@mixin h1 {
  margin: 0px;
  font-size: 1.40625rem;
  font-weight: 600;
  letter-spacing: -0.03em;
  line-height: 1.167;
}

@mixin h3 {
  font-weight: 600;
  font-size: 1.5625rem;
  letter-spacing: -.02em;
}

@mixin h4 {
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.235;
  letter-spacing: 0.00735em;
}

@mixin h5 {
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.0075em;
  line-height: 1.6;
}

@mixin h6 {
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: -.02em;
  line-height: 1.4;
}

@mixin h7 {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.3;
}

@mixin displayHeading {
  font-size: 2rem;
  @include breakpoints.up("sm") {
    font-size: 2.725rem;
  }
}

@mixin modalContentH2 {
  font-size: 14px;

  &:first-child {
    margin-top: 0;
  }
}

@mixin subtitle1 {
  font-size: 1.125rem;
  line-height: 1.4;
}

@mixin subtitle2 {
  font-size: 1.0625rem;
  line-height: 1.4;
}
